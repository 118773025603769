import React from 'react';
import { Menu, ModalButton } from '@web-passion/uikit';

import HeaderMenu from '../Header/menu';

import './mobile.scss';
import MenuItem from './item';

export default function MobileMenu({ menu }) {
  return (
    <div className="mobileNav">
      <ModalButton action="close" className="closeMobileNav">
        Close Menu
      </ModalButton>
      <Menu
        id="mobileMenu"
        items={menu}
        // customLink={item => <MenuLinks {...item} />}
        customItem={item => <MenuItem {...item} />}
        dropdown={{ effect: 'inline', offsetY: 0, trigger: 'click' }}
      />
      <HeaderMenu mobile />
    </div>
  );
}
